import React, { FC } from 'react';
import classNames from 'classnames';

import Typography from 'components/elements/Typography';
import GatsbyImage from 'components/helpers/GatsbyImage';

import { IPropsProductFeatures } from './models';

import './ProductFeatures.scss';

const ProductFeatures: FC<IPropsProductFeatures> = ({ data, variant }) => {
  const [{ items }] = data;

  return (
    <div
      data-testid="ProductFeatures"
      className={classNames('product-features', {
        [`product-features--variant-${variant}`]: variant,
      })}
    >
      {items.map((item) => (
        <div key={item.title[0].text} className="product-features__item">
          <GatsbyImage image={item.icon} className="product-features__image" />
          <Typography data={item.title} customClass="product-features__title" />
        </div>
      ))}
    </div>
  );
};

export default ProductFeatures;
