import React, { FC, useMemo } from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import useAlgoliaResponse from 'hooks/useAlgoliaResponse';
import useScreenRecognition from 'hooks/useScreenRecognition';

import { Container } from 'layout';
import Layout from 'layout/Layout';
import AlgoliaFilters from 'components/algolia/AlgoliaFilters';
import Pagination from 'components/elements/Pagination';
import HeroBanner from 'components/sections/HeroBanner';
import ProductsList from 'components/sections/ProductsList';
import { IProductCardInList } from 'components/sections/ProductsList/models';
import calculateLimitInListing from 'utils/listing';

import { IPropsPlpPage } from './models';

import './PlpPage.scss';

const COMPONENT_ID = 'productListFilter';

const PlpPage: FC<IPropsPlpPage> = ({
  data: { pageData, defaultProducts, plpSettings },
  pageContext,
}) => {
  const { search } = useLocation();

  const {
    seo,
    urls,
    createDate,
    updateDate,
    showBreadcrumbsDesktop,
    showBreadcrumbsMobile,
    filtersSection: filtersSectionPageData,
    pagination: paginationPageData,
    listingSettings: listingSettingsPageData,
    heroBanner,
  } = pageData;

  const { breadcrumbs, lang, url, category } = pageContext;
  const listingSettings = listingSettingsPageData?.length
    ? listingSettingsPageData
    : plpSettings.nodes[0].listingSettings;
  const pagination = paginationPageData?.length
    ? paginationPageData
    : plpSettings.nodes[0].pagination;
  const filtersSection = filtersSectionPageData?.length
    ? filtersSectionPageData
    : plpSettings.nodes[0].filtersSection;

  const { isMobile, isTablet, isInit } = useScreenRecognition();

  const products = defaultProducts?.nodes;
  const isSmallDevice = isMobile;
  const limit = calculateLimitInListing(isSmallDevice, isInit, listingSettings);

  const {
    saveAlgoliaHitsResponse,
    handleAlgoliaFiltersUsed,
    handleHitsResponseActivated,
    isHitsResponseActivated,
    itemsTotal,
    handleActiveListPage,
    activeListPage,
    paginatedItems,
    pageCount,
  } = useAlgoliaResponse({
    defaultItems: products || [],
    limitOnPage: limit,
    isSortByOrder: true,
  });

  const paginatedItemsSorted = useMemo(
    () =>
      paginatedItems?.map((item) => {
        const dataSource = products?.find((defaultItem) => item.url === defaultItem.url)!;

        return {
          ...dataSource,
        };
      }) as IProductCardInList[],
    [paginatedItems]
  );

  return (
    <Layout
      data-testid="PlpPage"
      seo={search ? { ...seo, noIndex: true } : seo}
      lang={lang}
      urls={urls}
      pagePathname={url}
      createDate={createDate}
      updateDate={updateDate}
      className="plp-page"
    >
      <HeroBanner
        data={heroBanner}
        breadcrumbs={breadcrumbs}
        isShowBreadcrumbsMobile={showBreadcrumbsMobile}
        isShowBreadcrumbsDesktop={showBreadcrumbsDesktop}
      />
      <Container>
        <div id={COMPONENT_ID} className="plp-page__filters-wrapper">
          <AlgoliaFilters
            indexName={`${process.env.GATSBY_ALGOLIA_INDEX}-products`}
            filtersSection={filtersSection}
            saveAlgoliaHitsResponse={saveAlgoliaHitsResponse}
            handleAlgoliaFiltersUsed={handleAlgoliaFiltersUsed}
            handleHitsResponseActivated={handleHitsResponseActivated}
            handleActiveListPage={handleActiveListPage}
            isHitsResponseActivated={isHitsResponseActivated}
            lang={lang}
            isSmallDevice={isSmallDevice}
            itemsTotal={itemsTotal}
            itemsCurrent={paginatedItems?.length || 0}
            category={category}
          />

          <ProductsList products={paginatedItemsSorted} isSmallDevice={isMobile || isTablet} />

          {itemsTotal > limit ? (
            <Pagination
              data={pagination}
              handleActiveListPage={handleActiveListPage}
              pageCount={pageCount}
              active={activeListPage}
              scrollTargetId={COMPONENT_ID}
              isSmallDevice={isSmallDevice}
            />
          ) : null}
        </div>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String!, $lang: String!, $category: Int) {
    pageData: plpPage(url: { eq: $url }, lang: { eq: $lang }) {
      ...FragmentPlpPage
    }
    defaultProducts: allProductPage(
      filter: { lang: { eq: $lang }, categories: { elemMatch: { id: { eq: $category } } } }
    ) {
      nodes {
        ...FragmentProductCard
      }
    }
    plpSettings: allPlpSettingsType(filter: { lang: { eq: $lang } }) {
      nodes {
        filtersSection {
          ...FragmentFiltersSection
        }
        pagination {
          ...FragmentPagination
        }
        listingSettings {
          ...FragmentCommonListingSettings
        }
      }
    }
  }
`;

export default PlpPage;
